import requestFile from '@/utils/requestFile'


export function pdfToWord(query) {
    return requestFile({
        url: '/pdftoword',
        method: 'post',
        data: query
    })
}
