<template>
    <div class="random">
        <div class="random-left">
            <div>
                <span class="title">PDF转Word</span>
                <span class="subtitle">把PDF文件转为可编辑Word文档</span>
            </div>
            <div class="line">
                <div class="pdf-converter">
                    <div class="selectedBtn">
                        <input type="file" accept=".pdf" @change="uploadChange" ref="pdf-upload-input"
                               class="pdf-upload-input">
                        <el-button size="small" plain @click="selectFile" :disabled="!checked"><i class="el-icon-upload"></i> {{ this.fileList.length==0?'选择文件':'重新选择'}}  </el-button>
                    </div>
                    <div
                            class="pdf-converter-con"
                            @dragover.prevent="handleDragOver"
                            @dragleave="handleDragLeave"
                            @drop.prevent="handleDrop"
                            @click="selectFile"
                            v-if="this.fileList.length == 0">
                        <span v-if="!isDragging">点击上方“选择文件”或将文件拖拽到此区域</span>
                        <span v-else>释放文件以上传</span>
                    </div>
                    <div class="pdf-list" v-else>
                        <table style="">
                            <thead>
                            <tr>
                                <th style="width: 160px;border: 1px solid black; padding: 8px;">文件名称</th>
                                <th style="width: 80px;border: 1px solid black; padding: 8px;">文件大小</th>
                                <th style="width: 80px;border: 1px solid black; padding: 8px;">文件状态</th>
                                <th style="width: 80px;border: 1px solid black; padding: 8px;">操作</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in newFileList" :key="index" style="font-size: 12px;">
                                    <td style="height: 30px;">{{ item.name }}</td>
                                    <td>
                                        {{ (item.size / 1024 / 1024).toFixed(2) }} MB
                                    </td>
                                    <td v-if="item.status==0"> <i class="el-icon-upload2"></i> 待上传</td>
                                    <td v-if="item.status==1"> <i class="el-icon-loading"></i> 处理中</td>
                                    <td v-if="item.status==2"> <i class="el-icon-download"></i> 处理成功</td>
                                    <td v-if="item.status==3"> <i class="el-icon-error"></i> 处理失败</td>
                                    <td>
                                        <el-button size="small" plain @click="fileRemove(index)" v-if="item.status==0">删除</el-button>
                                        <el-button size="small" plain v-if="item.status==1">请勿刷新</el-button>
                                        <el-button size="small" type="success" plain @click="downloadPDF(item.downloadUrl)" v-if="item.status==2">下载</el-button>
                                        <el-button size="small" type="info" plain @click="openYJ(item.url)" v-if="item.status==3">意见反馈</el-button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <el-button  :disabled="!checked" style="margin-left: 10px;float: right;margin-top: 20px;margin-bottom: 20px;" size="small" type="primary" @click="submitUpload">
                            开始转换
                        </el-button>
                    </div>
                    <div style="margin: 0 auto; margin-top: 20px;font-size: 14px;max-width: 450px;text-align: left;">
                        <el-checkbox v-model="checked"><span>我已阅读并遵守<a target="_blank" href="https://www.gov.cn/zwgk/2006-05/29/content_294000.htm">《信息网络传播权保护条例》</a></span></el-checkbox>
                    </div>
                </div>
            </div>
            <div style="margin-top: 20px;text-align: center" v-if="adSwitch">
                <div class="ad" style="height: 90px;text-align: center;"></div>
            </div>
            <div style="margin-top: 20px;text-align: center;">
                <el-image :src="pwd_img_url" width="95%" style="max-width: 550px;border-radius: 10px;"></el-image>
            </div>
            <div class="about">
                <el-collapse v-model="activeNames" @change="handleChange">
                    <el-collapse-item name="1">
                        <template slot="title">
                            <i class="el-icon-question"></i>&nbsp;如何将 PDF 转换为 Word？
                        </template>
                        <div>
                            <p>上传您的 PDF 文件并调整可选设置以满足您的需求。 如果您的 PDF 包含可编辑的文本，请选择“转换”。
                                如果您有扫描的 PDF 并需要可编辑，请选择“使用 OCR 转换”。 您可以选择文件中使用的语言来改进
                                OCR 结果。</p>

                            <p>1、上传您的PDF文件。</p>
                            <p>2、Choose Convert if your document does not contain any scanned images.</p>
                            <p>3、If you need text recognition choose Convert with OCR. Configure the OCR settings to
                                match your needs.</p>
                            <p>4、In the optional settings choose Microsoft Word (*.docx) or Word 2003 or older (*.doc)
                                from the dropdown menu.</p>
                            <p>5、点击“开始”。</p>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="我们确保您文件的安全！" name="2">
                        <template slot="title">
                            <i class="el-icon-success"></i>&nbsp;我们确保您文件的安全！
                        </template>
                        <div>
                            <p>
                                我们尊重隐私权。在文件转换一小时后，它们将永远从我们的服务器删除。如欲获取更多相关信息，请阅读我们的隐私政策。</p>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="适用于所有操作系统！" name="3">
                        <template slot="title">
                            <i class="el-icon-bangzhu"></i>&nbsp;适用于所有操作系统！
                        </template>
                        <div>
                            <p>我们的PDF转Word转换器适用于所有计算机，包括Mac、Windows及Linux。</p>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="提供最高质量的PDF转Word服务" name="4">
                        <template slot="title">
                            <i class="el-icon-magic-stick"></i>&nbsp;提供最高质量的PDF转Word服务！
                        </template>
                        <div>
                            <p>将PDF转换为Word文档的过程并不简单，因此我们网站作者就是老程序员。</p>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="数秒内将PDF转成DOC" name="5">
                        <template slot="title">
                            <i class="el-icon-question"></i>&nbsp;数秒内将PDF转成DOC！
                        </template>
                        <div>
                            <p>文件转换服务过程非常轻松，保证您一试便爱上。</p>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
        <div class="random-right">
            <div style=" height: 250px; margin-bottom: 8px; text-align: center; " v-if="adSwitch">

            </div>
            <div>
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>推荐工具</span>
                    </div>
                    <div v-for="(item,index) in recommendList" :key="index" style="margin-bottom: 10px">
                        <a :href="item.url" target="_blank" style="color: #3a8ee6;">{{ item.name }}</a>
                    </div>
                </el-card>
            </div>
        </div>
    </div>
</template>

<script>
import { pdfToWord } from "@/api/pdf/pdf";
import Table from "@/views/Test.vue";
import axios from 'axios'
export default {
    components: {Table},
    metaInfo: {
        title: 'PDF转Word转换器 - 100%免费',
        meta: [
            {
                charset: "utf-8"
            },
            {
                name: 'description,',
                content: '将 PDF 转换为可编辑的 Word 文档。 准确的结果将保留您的布局并支持 OCR。 无需安装软件。免费在线转换。市面上最优质的PDF转Word转换器 - 免费且易于使用。无附加水印 - 快速将PDF转成Word。'
            },
            {
                name: 'keywords',
                content: 'pdf转换成WORD的在线转换器，PDF转换成WORD，如何把PDF转换成WORD，PDF WORD转换器'
            },
            {
                name: 'og:site_name',
                content: 'PDF 到 Word 转换器 - 无需安装,PDF转Word转换器 - 100%免费'
            },
            {
                name: 'og:title',
                content: 'PDF 到 Word 转换器 - 无需安装'
            },
            {
                name: 'og:type',
                content: 'website'
            },
            {
                name: 'og:description',
                content: '将 PDF 转换为可编辑的 Word 文档。 准确的结果将保留您的布局并支持 OCR。 无需安装软件。'
            },
        ],
        link: [
            {
                rel: 'canonical',
                href: 'https://www.itptg.com/invest/'
            }
        ]
    },
    data() {
        return {
            pwd_img_url: require("@/assets/images/1720102071962.jpg"),
            activeNames: ['1', '2', '3', '4', '5'],
            adSwitch: false,
            recommendList: [
                {'name': '随机密码生成', 'url': '/'},
                {'name': '利息计算器', 'url': '/invest/'},
                {'name': '成本计算器', 'url': '/stockcost/'},
                {'name': '编码转换', 'url': '/stockcost/'}
            ],
            pdfUploadURL: process.env.VUE_APP_BASE_URL + '/pdftoword',
            fileList: [],
            newFileList: [],
            pdfFIle: null,
            isDragging: false,
            checked: false,
        }
    },
    watch: {
        'fileList': function(newVal, oldVal) {
            console.log(newVal)
            if (newVal.length > 0) {
                let fileObj = newVal[0]
                this.newFileList = [{'name':fileObj.name, 'size': fileObj.size,'status':0}]
            }else{
                this.newFileList.length = 0
            }

        }
    },
    computed: {},
    methods: {
        handleDragOver(event) {
            event.preventDefault();
            this.isDragging = true;
        },
        handleDragLeave() {
            this.isDragging = false;
        },
        handleDrop(event) {
            if(this.checked){
                event.preventDefault();
                this.isDragging = false;
                this.fileList = event.dataTransfer.files
            }else{
                this.$message.error('请先同意用户协议');
            }
        },
        handleChange(val) {
            console.log(val);
        },
        selectFile() {
            if(this.checked){
                this.$refs["pdf-upload-input"].click();
            }else{
                this.$message.error('请先同意用户协议');
            }
        },
        async uploadChange(event) {
            this.fileList = event.target.files
            console.log(this.fileList)
        },
        fileRemove(index) {
            this.fileList  = []
            this.$refs["pdf-upload-input"].value = null
            this.newFileList.length  = 0
        },
        submitUpload() {
            if(this.fileList.length > 0){
                this.newFileList.forEach(e=>{
                    e.status = 1
                })
                let param = new FormData(); //创建form对象
                param.append('file',this.fileList[0]);//通过append向form对象添加数据
                pdfToWord(param).then(res=>{
                    console.log(res)
                    if(res.code== 0){
                        this.newFileList.forEach(e=>{
                            e.status = 2
                            e.downloadUrl = res.url
                        })
                    }else{
                        this.newFileList.forEach(e=>{
                            e.status = 3
                        })
                    }
                }).catch(()=>{
                    this.newFileList.forEach(e=>{
                        e.status = 3
                    })
                })
            }
        },
        openYJ(){
            this.$alert('请加WX:m743106215 进行问题反馈', '意见反馈', {
                confirmButtonText: '确定',
                callback: action => {
                }
            });
        },
        downloadPDF(url){
            window.open(url)
        }
    }
}
</script>

<style scoped>


@media screen and (min-width: 800px) {
    .random {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border: 1px dashed #cccccc;
    }

    .random-left {
        margin: 5px;
        border: 1px solid #000000;
        flex-basis: 67%;
    }

    .random-left .line {
        border-top: 1px solid #000000;
    }

    .random-left .about {
        margin: 10px;
    }

    .title {
        font-weight: 700;
        font-size: 32px;
        display: inline-block;
        width: 100%;
        text-align: center;
        line-height: 60px;
        height: 60px;

        img {
            height: 32px;
            width: 32px;
        }
    }

    .subtitle {
        font-weight: 200;
        font-size: 14px;
        display: inline-block;
        width: 100%;
        text-align: center;
        line-height: 20px;
        height: 20px;
    }

    .pdf-upload-input {
        display: none;
    }

    .upload-pdf {
        border-collapse: collapse;
        max-width: 450px;
        margin: 0 auto;
        margin-top: 10px;
        font-size: 12px;
    }

    .selectedBtn {
        margin: 0 auto;
        max-width: 450px;
        text-align: left;
        height: 35px;
        /*border: 1px solid  #000000;*/
        padding-top: 20px;
    }

    .pdf-converter-con {
        margin: 0 auto;
        max-width: 450px;
        height: 220px;
        border: 1px dashed #bdb4b4;
        border-radius: 10px;
        text-align: center;
        line-height: 220px;
        color: rgba(0, 0, 0, .4);
        font-size: 14px;
        z-index: 9999999999;
    }

    .pdf-converter-con:hover {
        border: 1px dashed #000000;;
    }

    .pdf-list {
        margin: 0 auto;
        max-width: 450px;
    }

    .pdf-list table {
        border-collapse: collapse;
        width: 450px;
    }
    .pdf-list table tbody tr td{
        border: 1px solid black;
        padding: 8px;
        height: 20px
    }

    ::v-deep .upload-pdf .el-upload-dragger {
        background-color: #fff;
        border: 1px dashed #000000;
        border-radius: 6px;
        box-sizing: border-box;
        width: 450px;
        height: 220px;
        cursor: pointer;
        overflow: hidden;
    }

    ::v-deep .upload-pdf .el-upload-dragger:hover {
        background-color: #fff;
        border: 1px dashed #d22727;
        border-radius: 6px;
        box-sizing: border-box;
        width: 450px;
        height: 220px;
        text-align: center;
        cursor: pointer;
        overflow: hidden;
    }

    .about {
        text-align: left;
        text-indent: 2ch;
    }

    .random-right {
        flex-basis: 33%;
        margin: 5px;
    }

}


@media screen and (max-width: 799px) {
    .random {
        width: 100%;
        text-align: left;
        display: flex;
        flex-direction: column;
    }

    .random-left {
        margin: 5px;
        flex-basis: 100%;
    }

    .title {
        font-weight: 700;
        font-size: 32px;
        display: inline-block;
        width: 100%;
        text-align: center;
        line-height: 80px;
        height: 80px;
    }



    .pdf-upload-input {
        display: none;
    }

    .selectedBtn {
        margin: 0 auto;
        max-width: 450px;
        text-align: left;
        height: 35px;
        /*border: 1px solid  #000000;*/
        padding-top: 20px;
    }

    .subtitle {
        font-weight: 200;
        font-size: 14px;
        display: inline-block;
        width: 100%;
        text-align: center;
        line-height: 20px;
        height: 20px;
    }

    .pdf-converter-con {
        margin: 0 auto;
        max-width: 450px;
        height: 220px;
        border: 1px dashed #bdb4b4;
        border-radius: 10px;
        text-align: center;
        line-height: 220px;
        color: rgba(0, 0, 0, .4);
        font-size: 14px;
        z-index: 9999999999;
    }

    .pdf-converter-con:hover {
        border: 1px dashed #000000;;
    }

    .pdf-list {
        margin: 0 auto;
        max-width: 450px;
    }

    .pdf-list table {
        border-collapse: collapse;
        width: 450px;
    }
    .pdf-list table tbody tr td{
        border: 1px solid black;
        padding: 8px;
        height: 20px
    }

    ::v-deep .upload-pdf .el-upload-dragger {
        background-color: #fff;
        border: 1px dashed #000000;
        border-radius: 6px;
        box-sizing: border-box;
        width: 450px;
        height: 220px;
        cursor: pointer;
        overflow: hidden;
    }

    ::v-deep .upload-pdf .el-upload-dragger:hover {
        background-color: #fff;
        border: 1px dashed #d22727;
        border-radius: 6px;
        box-sizing: border-box;
        width: 450px;
        height: 220px;
        text-align: center;
        cursor: pointer;
        overflow: hidden;
    }

    .password-table td {
        border: 1px solid black;
        padding: 8px;
    }

    .random-right {
        display: none;
    }
}
</style>
